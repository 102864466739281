.chart-container {
  display: block;
  position: relative;
}
.number-of-text {
  position: absolute;
  top: 4rem;
  left: 0rem;
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.histogram {
  margin-left: 2.25rem;
}
