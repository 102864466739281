.App {
  text-align: center;
}

.ant-back-top {
  right: 16px !important;
  bottom: 75px !important;
}

.ant-message {
  top: 72px !important;
}

.ant-tabs-nav-list {
  margin-left: 1rem;
}

.ant-picker-calendar-header {
  margin-right: 1rem !important;
}

/* used for depot country flats */
.ant-avatar {
  border-radius: 0 !important;
  width: 48px !important;
  height: 32px;
}

.ant-modal-title {
  color: rgb(255 255 255) !important;
}

.ant-modal-header {
  background-color: #1890ff !important;
}

.ant-modal-footer {
  background-color: #eee !important;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-table-tbody > tr > td {
  vertical-align: top;
}

.disabled-row {
  color: rgba(0, 0, 0, 0.35);
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}


.ant-table-cell .anticon-edit:hover {
  color: white !important;
  background-color: #1890FF;
  border-radius: 4px;
}

.attention-animation {
  display: inline-block;
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-25px);
  }
  60% {
    transform: translateY(-15px);
  }
}
