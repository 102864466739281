* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.site-layout-background {
  height: 100%;
  width: 100%;
  margin-bottom: 48px;
  min-height: 100vh !important;
}

.site-main-content {
  background: white;
  margin: 100px 40px;
}

.site-dashboard-content {
  background: white;
  margin: 10px 10px;
}


.site-view-welcome {
  position: relative;
  height: 100vh;
  width: 100%;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/splash2.jpg');
}

.header-logo {
  float: left;
  width: 48px;
  height: 32px;
  margin: 16px 0 16px 0;
  padding: 0;
}

.site-main-content-transparent {
  background: transparent !important;
}

.caption {
  position: absolute;
  left: 0;
  top: 30%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.header {
  position: relative;
  background-color: #001529;
  color: #fff;
  padding: 20px;
  width: 100%;
  font-size: 36px;
  letter-spacing: 4px;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.panel-body-no-padding .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-table-pagination.ant-pagination {
  margin-right: 16px !important;
}

@media (max-width: 1400px) {
  .site-main-content {
    margin: 100px 8px;
  }
}
