@import '../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

.maintenance-work-event {
  background-color: rgb(136, 68, 68) !important;
}
.technical-issue-event {
  background-color: rgb(67, 136, 67) !important;
}
.products-shortage-event {
  background-color: cornflowerblue !important;
}
.boat-unloading-event {
  background-color: rgb(202, 68, 68) !important;
}
.other-events {
  background-color: rgb(136, 63, 136) !important;
}
